<template>
	<form @submit.prevent="saveItem">
		<b-card>
			<b-col cols="12" class="p-0">
				<template v-if="isLoading">
					<b-spinner
						class="mb-1"
						variant="primary"
					/>
					<br>
						Loading...
				</template>
				<template v-else>
					<p class="mb-2">Biaya admin dihitung dari total biaya yang dibayar</p>
					<b-row v-for="payments , key in paymentMethodGroups" :key="key">
						<b-col cols="12" class="my-2">
							<h3>{{ key }}</h3>
						</b-col>
						<b-col cols="12" md="6" v-for="items in payments" :key="items.payment_method.slug">
							<div class="form-group d-flex flex-column">
								<label>{{ items.payment_method.name }}</label>
								<div class="form-check form-check-inline mb-2" style="gap:10px;" >
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Rp</span>
										</div>
										<input 
											type="number" 
											class="form-control"
											v-model="items.amount"
											:disabled="items.type != 'nominal'"
										>
									</div>
									<div class="custom-control custom-checkbox">
										<input 
											:name="'nominal' + items.payment_method.slug " 
											class="custom-control-input" 
											type="radio" 
											:id="'radioNominal' + items.payment_method.slug" 
											value="nominal" 
											v-model="items.type"
										>
										<label 
											class="custom-control-label" 
											:for="'radioNominal' + items.payment_method.slug" 
										>
										</label>
									</div>
								</div>
								<div class="form-check form-check-inline" style="gap:10px;">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">%</span>
										</div>
										<input 
											type="number" 
											class="form-control"
											v-model="items.amount_percent"
											:disabled="items.type != 'percent'"
										>
									</div>
									<div class="custom-control custom-checkbox">
										<input 
											:name="'percent' + items.payment_method.slug" 
											class="custom-control-input"
											type="radio" 
											:id="'radioPercent' + items.payment_method.slug"  
											value="percent" 
											v-model="items.type"
										>
										<label 
											class="custom-control-label" 
											:for="'radioPercent' + items.payment_method.slug" 
										>
										</label>
									</div>
								</div>
								<!-- <small
									v-for="(validation, index) in validations['items.' + index + '.amount']"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small> -->
							</div>
						</b-col>
					</b-row>
				</template>
			</b-col>
		</b-card>
		<b-col cols="12" class="p-0 mt-2">
		<template v-if="isLoading">
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
				disabled
			>
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</button>
		</template>
		<template v-else>
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
			>
				Save
			</button>
		</template>
		</b-col>
	</form>
</template>

<script>
import 
	{ 
		BCard, 
		BCardText, 
		BForm, 
		BButton,
		BCol,
		BRow,
		BSpinner,
		BFormFile,
	} from 'bootstrap-vue'
import { successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'

export default {
	components: {
		BCard,
		BCardText,
		BForm,
		BButton,
		BCol,
		BRow,
		BSpinner,
		BFormFile,
	},

	data() {
		return {
			// inputAdminfee: [],
			adminFee: {},
			listPayment: [],
			isLoading: false,
			validations: '',
			paymentMethodGroups: {},
		}
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	methods: {

		getDataAdminfee() { 
			this.isLoading = true
			this.$http.get('admin/admin-fee/pkb-payment'
			).then(response => {
				this.adminFee = response.data.data
				this.adminFee.items.forEach((element, index) => {
					element['amount_percent'] = 0
					if (element.type == 'percent') {
						element['amount_percent'] = element.amount
						element.amount = 0
					}
					this.paymentMethodGroups[element.payment_method.group] = this.adminFee.items.filter(item => item.payment_method.group == element.payment_method.group)
				});
				this.isLoading = false
			}).catch(error => {
				this.isLoading = false
			})
		},

		saveItem() {
			this.isLoading = true
			const payload = new FormData()
			if (this.adminFee.items !== null) {
				this.adminFee.items.forEach((items, index) => {
					Object.keys(items).forEach(key => {
						if (key == 'payment_method' || key == 'amount' || key == 'type') {
							if (key == 'payment_method') {
								payload.append('items' + '['+ index + ']['+ key +'_slug]', items[key].slug);
							} else if (key == 'amount') {
								if (items.type == 'percent') {
									payload.append('items' + '['+ index + '][' + key + ']', items['amount_percent']);
								} else {
									payload.append('items' + '['+ index + '][' + key + ']', items[key]);
								}
							} else {
								payload.append('items' + '['+ index + '][' + key + ']', items[key]);
							}
						}						
						
					});
				});
			}
			let uuid = this.adminFee.uuid
			this.$http.post('admin/admin-fee/' + uuid, payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			}).then(response => {
				successNotification(this, 'Success', 'Biaya admin berhasil diedit!')
				this.isLoading = false
				this.validations = ''
			}).catch(error => {
				this.validations = error.response.data.meta.validations
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Input ada yang tidak terisi') 
					this.isLoading = false
				}
				this.isLoading = false
			})
		}

	},

	created() {
		// this.getPaymentList()
		this.getDataAdminfee()
	}
}
</script>

<style lang="scss">
.images__hint {
	font-size: 10px;
	margin-bottom: 0;
}
.input-group-text {
	min-width: 48px;
}
</style>
